import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  fetchCodeDetails,
  fetchDonations,
  fetchCartDetails,
} from "../../../shared/utilities/actions";
import {
  CodeDetails,
  DefaultCodeDetails,
  Donation,
} from "../../../shared/types";
import { SpendButton } from "../components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export interface MainProps {
  hideOnSpend: boolean;
  hideOnBuy: boolean;
}

export const Main = ({
  hideOnSpend,
  hideOnBuy,
}: MainProps): JSX.Element | null => {
  const [busy, setBusy] = useState<boolean>(true);
  const [codeDetails, setCodeDetails] = useState<CodeDetails>({
    ...DefaultCodeDetails,
  });
  const [cartItemCount, setCartItemCount] = useState<number>(0);
  const [donations, setDonations] = useState<Donation[]>([]);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      fetchDonations(setDonations);
      fetchCodeDetails(codeDetails.themeId, setCodeDetails);
      setBusy(false);
    };
    fetchData();
  }, []);

  if ((codeDetails.valid && hideOnSpend) || (cartItemCount > 0 && hideOnBuy)) {
    return null;
  } else {
    return (
      <SpendButton
        codeDetails={codeDetails}
        donations={donations}
        mobile={mobile}
      />
    );
  }
};
