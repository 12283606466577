import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { Button } from "@mui/material";
import { CodeDetails, Donation } from "../../../shared/types";
import { convertToCurrency } from "../../../shared/utilities/tools";
import { VolunteerActivism as VolunteerActivismIcon } from "@mui/icons-material";

export interface SpendButtonProps {
  codeDetails: CodeDetails;
  donations: Donation[];
  mobile: boolean;
}

export const SpendButton = ({
  codeDetails,
  donations,
  mobile,
}: SpendButtonProps): JSX.Element | null => {
  if (codeDetails.valid && !codeDetails.spendCompleted) {
    let currentDonationAmount = 0;
    donations.map((donation) => {
      currentDonationAmount += donation.amount;
    });

    return (
      <Button
        variant="outlined"
        onClick={() => {
          window.location.href = "/charity-checkout/";
        }}
        className="tb_spend_validated_button"
      >
        {codeDetails.availableAmount > 0 ? (
          mobile ? (
            "Donate"
          ) : (
            <React.Fragment>
              <VolunteerActivismIcon
                sx={{ marginRight: "5px", marginBottom: "3px" }}
              />
              {convertToCurrency(codeDetails.originalAmount)}
            </React.Fragment>
          )
        ) : (
          "Donated"
        )}
      </Button>
    );
  } else {
    return (
      <Button
        variant="outlined"
        onClick={() => {
          window.location.href = "#dialog-code";
        }}
        className="tb_spend_button"
      >
        {mobile ? "Spend" : "Spend a Gift Card"}
      </Button>
    );
  }
};
